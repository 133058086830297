import React from "react"

import RichText from "../../components/RichText/RichText"

import Seo from "../../components/Seo/seo"
import { graphql, useStaticQuery } from "gatsby"
import { ContentfulTermsAndConditionsQuery } from "../../../types/gatsby-graphql"
import Container from "../../components/Container/Container"

import * as styles from "./TermsAndConditions.module.scss"

const TermsAndConditions = () => {
  const data: ContentfulTermsAndConditionsQuery = useStaticQuery(graphql`
    query ContentfulTermsAndConditions {
      contentfulTermsAndConditions {
        title
        version
        termsAndConditions {
          raw
        }
      }
    }
  `)

  return (
    <>
      <Seo title="Terms & conditions" />
      <Container>
        <div className={styles.terms}>
          <Seo title="Terms & Conditions" />
          <h1>{data.contentfulTermsAndConditions?.title}</h1>
          <RichText
            richText={data.contentfulTermsAndConditions?.termsAndConditions}
          />
        </div>
      </Container>
    </>
  )
}

export default TermsAndConditions
